/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
// A dynamic form generator component which will generate a form based on the schema passed to it.
// Will be using react hook form library to generate the form.

// get the schmea from the props
// generate the form based on the schema
// return the form

// Schema will be an array of objects
// each object will have a type, label, name, value, options, etc
// type will be used to determine the type of input to be used
// label will be used to determine the label of the input
// name will be used to determine the name of the input
// value will be used to determine the value of the input
// options will be used to determine the options of the input

// form layout will be an array of arrays
// each array will have objects
// each object will have a name and width
// name will be used to determine the name of the input
// width will be used to determine the width of the input to be set for the 'grid item'

// field type could be a text, email, password, number, date, select, switch, slider, autocomplete, checkbox, radio, textarea, image, file, etc

// field type could also be a custom data type
// custom data type could be a

// form could be for both create and update
// create will have a submit button
// update will have a save button
// 'formMode' prop will determine if the form is for create or update

// form will have a cancel button

// form view can be a modal or a new page
// 'formType' prop will determine if the form is a modal or a new page
// modal mode will be the default mode

// if in modal mode, the form will have a close button
// if in new page mode, the form will have a back button

// modal will a right side drawer
// new page will be a new page

// form will have a title
// title will be passed as a prop

// will use the useEntity hook to create and update the entity

// formLayout can have other Class Types like CookingActions, Ingredients, etc
// Will use Autocomplete to select the Class Type

import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { recipeListValidation } from "../recipe-filters/recipe-filters-model";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  Slider,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import { useController, useForm } from "react-hook-form";
import useEntity from "./use-entity";
import ImagesDropzone from "../recipes/images-drop-zone-old";
// import ReactJson from "react-json-view";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

// Input Fields
import ControlledAutoComplete from "./form-field-components/controlled-autocomplete";
import ControlledSelect from "./form-field-components/controlled-select";
import ControlledMediaSelect from "./form-field-components/controlled-media-select";
import ControlledCheckbox from "./form-field-components/controlled-checkbox";
import ImageUploader from "./form-field-components/image-uploader";
import ControlledAutoCompleteArray from "./form-field-components/controlled-autocomplete-array";

import { fi } from "date-fns/locale";
import Customlayoutselect from "./form-field-components/custom-layout-select";
import ControlledMultiMediaSelect from "./form-field-components/controlled-multi-media-select";
import { FirebaseApi, firestore } from "../../config/firebase-config";

import { useAuth } from "../auth/auth-context";
import { userLogsCollection } from "../../utils/constants";

const DEBUG = true;

// TODO: What if some fields have to be post processes like no matter what the user types will make it uppercase or lowercase or trim the spaces or remove special characters, etc
// TOOD: Accomodate this in the schema and the form generator
// TODO: for e.g All constant entities should have the names to be in camel case

// Will autoupdate the field based on the value of the field passed in the derivedFrom prop
// eg. if actionName is passed in the derivedFrom prop, then the actionId will be updated with the id corresponding to actionName in actions collection
// Will return a text field with autoupdate prop
function AutoupdateField(props) {
  const {
    field,
    control,
    name,
    derivedFrom,
    derivedFromcollection,
    collectionLookupKey,
    collectionReturnKey,
    setFormValue,
    watch,
    register,
    setValue,
  } = props;

  // const { field } = useController({
  //   name,
  //   control,
  //   //   rules: { required: true },
  // });

  // watch the derived from key and update the value of the field
  const watchDerivedFrom = watch(derivedFrom);

  useEffect(() => {
    var derivedValue = derivedFromcollection.filter(
      (obj) => obj[collectionLookupKey] === watchDerivedFrom
    );
    if (derivedValue.length > 0) {
      derivedValue = derivedValue[0][collectionReturnKey];
      setFormValue(name, derivedValue);
    }
  }, [watchDerivedFrom]);

  return (
    <FormControl>
      <FormLabel>{field.label}</FormLabel>
      <TextField
        size="small"
        fullWidth
        disabled
        // label={field.label}
        variant="outlined"
        {...register(field.name)}
      />
    </FormControl>
  );
}
function SimpleAutoupdateField(props) {
  const { field, control, name, derivedFrom, setValue, watch, register } =
    props;
  // const { field } = useController({
  //   name,
  //   control,
  //   //   rules: { required: true },
  // });

  // watch the derived from key and update the value of the field
  const watchDerivedFrom = watch(derivedFrom);

  useEffect(() => {
    if (watchDerivedFrom === undefined || watchDerivedFrom === null) return;
    let derivedValue = watchDerivedFrom.replace(/\s/g, "-").toLowerCase();
    setValue(name, derivedValue);
  }, [watchDerivedFrom, name, setValue]);

  return (
    <FormControl>
      <FormLabel>{field.label}</FormLabel>
      <TextField
        size="small"
        fullWidth
        // label={field.label}
        variant="outlined"
        {...register(field.name)}
      />
    </FormControl>
  );
}

// function  ControlledSelectWithOptionsObject(props) {
//   const { control, name, options, multiple, label } = props;
//   const {
//     field,
//     fieldState: { invalid, isTouched, isDirty },
//     formState: { touchedFields, dirtyFields },
//   } = useController({
//     name,
//     control,
//     rules: { required: true },
//   });
//   return (
//     <Select
//       size={props.size ? props.size : "small"}
//       label={label}
//       id={field.name}
//       onChange={field.onChange}
//       onBlur={field.onBlur}
//       name={field.name}
//       inputRef={field.ref}
//       value={field.value}
//       fullWidth
//       multiple={multiple}
//     >
//       {options.map((option) => (
//         <MenuItem key={option.value} value={option.value}>
//           {option.label}
//         </MenuItem>
//       ))}
//     </Select>
//   );
// }

export default function FormGenerator(props) {
  const { schema, mode, values, updateValue, toggleModal, storageLocation } =
    props;
  const uniqueId = uuidv4();
  const {
    loading,
    loadingMessage,
    getEntitiesAsArray,
    addEntity,
    updateEntity,
  } = useEntity();
  const { user } = useAuth();
  const [fieldsToWatch, setFieldsToWatch] = useState([]);
  const [formDefaultValues, setFormDefaultValues] = useState(values);
  const [imageList, setImageList] = useState([]);
  const [classTypeCollection, setClassTypeCollection] = useState({});
  const [recipeFilterUpdate, setRecipeFilterUpdate] = useState({});
  const [watchFieldsToDeriveFields, setWatchFieldsToDeriveFields] = useState(
    []
  );
  const [nextLayoutIndex, setNextLayoutIndex] = useState(null);

  const [descriptionCharCount, setDescriptionCharCount] = useState(0);
  const [textCharCount, setTextCharCount] = useState(0);
  const [subtitletextModifiedCharCount, setSubtitletextModifiedCharCount] =
    useState(0);
  const [textModifiedCharCount, setTextModifiedCharCount] = useState(0);
  const [aliasestextModifiedCharCount, setAliasesTextModifiedCharCount] =
    useState(0);
  const [sysnonymstextModifiedCharCount, setSysnonymstextModifiedCharCount] =
    useState(0);
  const [
    firmwareNametextModifiedCharCount,
    setfirmwareNametextModifiedCharCount,
  ] = useState(0);
  const entityName = schema.collectionName;
  const formMode = mode;

  useEffect(() => {
    if (formMode === "create") {
      getNextLayoutIndex();
    }
  }, [formMode]);

  const getNextLayoutIndex = async () => {
    try {
      const snapshot = await firestore
        .collection("recipeFilterGroups")
        .orderBy("layoutIndex", "desc")
        .limit(1)
        .get();
      const highestIndex = snapshot.empty
        ? 0
        : snapshot.docs[0].data().layoutIndex;
      setNextLayoutIndex(Math.max(highestIndex + 1, 1));
    } catch (error) {
      console.error("Error getting next layout index:", error);
    }
  };

  const formFieldsArrayToObj = (formFieldArray) => {
    let formFieldsObj = {};
    formFieldArray.forEach((field) => {
      formFieldsObj[field.name] = field;
    });
    return formFieldsObj;
  };

  // Setup Image Dropzone
  const changeImageField = (index, parameter, value) => {
    const newArray = [...imageList];
    newArray[index][parameter] = value;
    setImageList(newArray);
    // eslint-disable-next-line no-unused-expressions
    ["FINISH", "UPLOADING"].includes(value)
      ? null
      : setFormValue("thumbnail", value);
  };

  useEffect(() => {
    imageList.forEach((image, index) => {
      if (image.status === "FINISH" || image.status === "UPLOADING") return;
      changeImageField(index, "status", "UPLOADING");
      const uploadTask = image.storageRef.put(image.file);
      uploadTask.on(
        "state_changed",
        null,
        function error(err) {
          console.log("Error Image Upload:", err);
        },
        async function complete() {
          const downloadURL = await uploadTask.snapshot.ref.getDownloadURL();
          changeImageField(index, "downloadURL", downloadURL);
          changeImageField(index, "status", "FINISH");
        }
      );
    });
  }, [imageList]);

  // if any of the fields is a class type, then get the entities of that class type

  const addToClassTypeCollection = (classType, entities) => {
    setClassTypeCollection((prevState) => {
      return {
        ...prevState,
        [classType]: entities,
      };
    });
  };

  useEffect(() => {
    schema.fields.forEach((field) => {
      if (field.collectionName === "recipeCards") {
        let recipeEntities = [];
        getEntitiesAsArray(field.collectionName).then((entities) => {
          entities.forEach((item) => {
            recipeEntities.push({ id: item.id, title: item.title });
            setRecipeFilterUpdate((prevState) => ({
              ...prevState,
              [item.id]: item,
            }));
          });
          addToClassTypeCollection(field.collectionName, recipeEntities);
        });
      } else if (field.collectionName) {
        getEntitiesAsArray(field.collectionName).then((entities) => {
          addToClassTypeCollection(field.collectionName, entities);
        });
      }
      if (field.validation) {
        setDefaultValidationSchemaObject((prevState) => {
          return {
            ...prevState,
            [field.name]: field.validation,
          };
        });
      }
    });
    return () => {};
  }, []);

  const fieldsObj = formFieldsArrayToObj(schema.fields);

  const formLayout = schema.layout;

  const [defaultValidationSchemaObject, setDefaultValidationSchemaObject] =
    useState({});
  const [validationSchema, setValidationSchema] = useState(
    yup.object().shape(defaultValidationSchemaObject)
  );

  const {
    control,
    register,
    setValue,
    setFormValue,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    // reValidateMode: 'onChange',
    defaultValues:
      formDefaultValues && formMode === "edit"
        ? formDefaultValues
        : {
            ...schema.defaultObject,
            id: uniqueId,
            layoutIndex: nextLayoutIndex,
          },
    resolver: yupResolver(yup.object().shape(defaultValidationSchemaObject)),
  });

  const watchAllFields = watch();

  useEffect(() => {
    schema.fields.forEach((field) => {
      if (field.derived) {
        setFieldsToWatch((prevState) => {
          // create a set of all the fields that are derived
          let newState = Array.from(
            new Set([...prevState, ...field.derivedFrom])
          );

          return newState;
        });
      }
      // setFieldsToWatch(deriveValuesHash);
    });
  }, [schema.fields]);

  useEffect(() => {
    schema.fields.forEach((field) => {
      const value = watch(field.name) || "";
      switch (field.inputFieldType) {
        case "text":
          setTextCharCount(value.length);
          break;
        case "subtitle-text":
          setSubtitletextModifiedCharCount(value.length);
          break;
        case "text-modified":
          setTextModifiedCharCount(value.length);
          break;
        case "firmwareName-text-modified":
          setfirmwareNametextModifiedCharCount(value.length);
          break;
        case "aliases-text-modified":
          setAliasesTextModifiedCharCount(value.length);
          break;
        case "synonyms-text-modified":
          setSysnonymstextModifiedCharCount(value.length);
          break;
        case "textarea":
          setDescriptionCharCount(value.length);
          break;
      }
    });
  }, [schema.fields, watch]);

  // Check if any of the fields have a derived key set to true
  // If so, then add a watch to the field and update the value of the derived field when the watched field changes based on the function passed in  "derivedFunction" key
  // The fields that are used in the derived function are passed in the "derivedFrom" key

  // useEffect(() => {
  //   schema.fields.forEach((field) => {
  //     if (field.derived) {
  //       const derivedFrom = field.derivedFrom.map(
  //         (field) => watchAllFields[field]
  //       );

  //       const derivedValue = field.derivedFunction(...derivedFrom);

  //       // setFormValue(field.name, derivedValue)

  //       setFormValue(field.name, derivedValue);
  //     }
  //   });
  // }, [watchFieldsToDeriveFields]);

  const recipeFilterUpdatingToRecipeCards = async (data, title) => {
    if (!data?.recipeList || !recipeFilterUpdate) {
      console.error("Invalid data or recipeFilterUpdate is not defined.");
      return;
    }
    for (const item of data.recipeList) {
      if (recipeFilterUpdate.hasOwnProperty(item.id)) {
        const smartFilters = [
          ...recipeFilterUpdate[item.id].smartFilters,
          title,
        ];
        await updateEntity(
          "recipeCards",
          { ...recipeFilterUpdate[item.id], smartFilters },
          setFormDefaultValues
        );
      } else {
        console.error(`No entry found for ${item.id} in recipeFilterUpdate.`);
      }
    }
  };

  const handleMediaUpload = (files) => {
    console.log("Uploaded files:", files);
  };
  function findDifferences(obj1, obj2) {
    return _.reduce(
      obj1,
      (result, value, key) => {
        if (!_.isEqual(value, obj2[key])) {
          result[key] = { old: value, new: obj2[key] };
        }
        return result;
      },
      {}
    );
  }

  const addUserLogsHandler = async (data) => {
    try {
      const userLogs = {
        userId: user?.userId || "",
        userName: user?.displayName || "",
        createdAt: "",
        operationType: formMode === "edit" ? "edit" : "create",
        module: entityName,
        diiferenceOfRecord:
          formMode === "edit"
            ? JSON.stringify(findDifferences(formDefaultValues, data))
            : "",
        newRecord: JSON.stringify(data),
      };

      await FirebaseApi[userLogsCollection].add(userLogs);
    } catch (error) {
      console.error("Error writing user log: ", error);
    }
  };

  const onSubmit = async (data) => {
    if (formMode === "create") {
      data.layoutIndex = nextLayoutIndex;
    }

    if (schema.collectionName === "recipeFilters") {
      const operation = formMode === "edit" ? updateEntity : addEntity;
      await recipeFilterUpdatingToRecipeCards(data, data.title);
      await operation(entityName, data, setFormDefaultValues);
      await updateValue(data);

      // Updating the nextLayoutIndex after successful creation
      if (formMode === "create") {
        setNextLayoutIndex((prevIndex) => prevIndex + 1);
      }
    } else {
      const operation = formMode === "edit" ? updateEntity : addEntity;
      await operation(entityName, data, setFormDefaultValues);
      await updateValue(data);
    }
    await addUserLogsHandler(data);
    toggleModal();
  };

  const returnField = (field) => {
    switch (field?.inputFieldType) {
      case "text":
        return (
          <FormControl fullWidth error={textCharCount > 60}>
            <TextField
              size="small"
              fullWidth
              label={
                <>
                  {field.label} <span style={{ color: "red" }}>*</span>
                </>
              }
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) => setTextCharCount(e.target.value.length),
              })}
              error={!!errors[field.name] || textCharCount > 60}
              helperText={
                errors[field.name]?.message ||
                (textCharCount > 60
                  ? "Error: Maximum 60 characters allowed"
                  : `${textCharCount}/60 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "60",
                },
              }}
            />
          </FormControl>
        );
      case "subtitle-text":
        return (
          <FormControl fullWidth error={subtitletextModifiedCharCount > 60}>
            <TextField
              size="small"
              fullWidth
              label={
                <>
                  {field.label} <span style={{ color: "red" }}>*</span>
                </>
              }
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) =>
                  setSubtitletextModifiedCharCount(e.target.value.length),
              })}
              error={!!errors[field.name] || subtitletextModifiedCharCount > 60}
              helperText={
                errors[field.name]?.message ||
                (subtitletextModifiedCharCount > 60
                  ? "Error: Maximum 60 characters allowed"
                  : `${subtitletextModifiedCharCount}/60 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "60",
                },
              }}
            />
          </FormControl>
        );
      case "noChar-text":
        return (
          <TextField
            size="small"
            fullWidth
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "noChar-noValid-text":
        return (
          <TextField
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "text-modified":
        return (
          <FormControl fullWidth error={textModifiedCharCount > 40}>
            <TextField
              size="small"
              fullWidth
              label={
                <>
                  {field.label} <span style={{ color: "red" }}>*</span>
                </>
              }
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) =>
                  setTextModifiedCharCount(e.target.value.length),
              })}
              error={!!errors[field.name] || textModifiedCharCount > 40}
              helperText={
                errors[field.name]?.message ||
                (textModifiedCharCount > 40
                  ? "Error: Maximum 40 characters allowed"
                  : `${textModifiedCharCount}/40 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "40",
                },
              }}
            />
          </FormControl>
        );
      case "firmwareName-text-modified":
        return (
          <FormControl fullWidth error={firmwareNametextModifiedCharCount > 40}>
            <TextField
              size="small"
              fullWidth
              label={
                <>
                  {field.label} <span style={{ color: "red" }}>*</span>
                </>
              }
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) =>
                  setfirmwareNametextModifiedCharCount(e.target.value.length),
              })}
              error={
                !!errors[field.name] || firmwareNametextModifiedCharCount > 40
              }
              helperText={
                errors[field.name]?.message ||
                (firmwareNametextModifiedCharCount > 40
                  ? "Error: Maximum 40 characters allowed"
                  : `${firmwareNametextModifiedCharCount}/40 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "40",
                },
              }}
            />
          </FormControl>
        );
      case "aliases-text-modified":
        return (
          <FormControl fullWidth error={aliasestextModifiedCharCount > 60}>
            <TextField
              size="small"
              fullWidth
              label={field.label}
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) =>
                  setAliasesTextModifiedCharCount(e.target.value.length),
              })}
              error={!!errors[field.name] || aliasestextModifiedCharCount > 60}
              helperText={
                errors[field.name]?.message ||
                (aliasestextModifiedCharCount > 60
                  ? "Error: Maximum 60 characters allowed"
                  : `${aliasestextModifiedCharCount}/60 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "60",
                },
              }}
            />
          </FormControl>
        );
      case "synonyms-text-modified":
        return (
          <FormControl fullWidth error={sysnonymstextModifiedCharCount > 40}>
            <TextField
              size="small"
              fullWidth
              label={field.label}
              variant="outlined"
              {...register(field.name, {
                required: true,
                onChange: (e) =>
                  setSysnonymstextModifiedCharCount(e.target.value.length),
              })}
              error={
                !!errors[field.name] || sysnonymstextModifiedCharCount > 40
              }
              helperText={
                errors[field.name]?.message ||
                (sysnonymstextModifiedCharCount > 40
                  ? "Error: Maximum 40 characters allowed"
                  : `${sysnonymstextModifiedCharCount}/40 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "40",
                },
              }}
            />
          </FormControl>
        );
      case "email":
        return (
          <TextField
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "password":
        return (
          <TextField
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "number":
        return (
          <TextField
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "date":
        return (
          <TextField
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "select":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            options={field.options}
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            control={control}
            name={field.name}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
            multiple={false}
            placeholder={field.placeholder}
          />
        );
      case "notRequired-select":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            options={field.options}
            label={field.label}
            control={control}
            name={field.name}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
            multiple={false}
            placeholder={field.placeholder}
          />
        );
      case "img-select":
        return (
          <Customlayoutselect
            size="small"
            fullWidth
            options={field.options}
            label={field.label}
            control={control}
            name={field.name}
            value={field.value}
            onChange={field.onChange}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "multi-select":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            options={field.options}
            label={field.label}
            control={control}
            name={field.name}
            multiple={true}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "select-from-collection":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? Array.from(
                    new Set(
                      classTypeCollection[field.collectionName].map(
                        (obj) => obj[field["keyInCollection"]]
                      )
                    )
                  )
                : []
            }
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            control={control}
            name={field.name}
            multiple={field.multiple}
          />
        );
      case "fdcName-select-from-collection":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? Array.from(
                    new Set(
                      classTypeCollection[field.collectionName].map(
                        (obj) => obj[field["keyInCollection"]]
                      )
                    )
                  )
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
            multiple={field.multiple}
          />
        );
      case "media-select-from-collection":
        return (
          <ControlledMediaSelect
            size="small"
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? Array.from(
                    new Set(
                      classTypeCollection[field.collectionName].map(
                        (item) => item[field.keyInCollection]
                      )
                    )
                  )
                : []
            }
            control={control}
            label={field.label}
            name={field.name}
            handleMediaUpload={handleMediaUpload}
            storageLocation={field.collectionName}
          />
        );
      case "multi-select-from-collection":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            multiple={field.multiple}
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName].map(
                    (obj) => obj[field["keyInCollection"]]
                  )
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
          />
        );
      case "food-category-multi-select-from-collection":
        return (
          <ControlledSelect
            size="small"
            fullWidth
            multiple={field.multiple}
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName].map(
                    (obj) => obj[field["keyInCollection"]]
                  )
                : []
            }
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            control={control}
            name={field.name}
          />
        );
      case "media-multi-select-from-collection":
        return (
          <ControlledMultiMediaSelect
            size="small"
            fullWidth
            multiple={field.multiple}
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName].map(
                    (obj) => obj[field["keyInCollection"]]
                  )
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
            isCollectionSelect={true}
          />
        );
      case "autoupdate":
        // TODO:  Inefficient way of doing this. Need to find a better way
        return (
          <AutoupdateField
            derivedFromcollection={
              classTypeCollection[field.fromCollection]
                ? classTypeCollection[field.fromCollection]
                : []
            }
            field={field}
            derivedFrom={field.derivedFrom}
            collectionLookupKey={field.collectionLookupKey}
            collectionReturnKey={field.collectionReturnKey}
            control={control}
            name={field.name}
            setFormValue={setFormValue}
            watch={watch}
            register={register}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "simple-autoupdate":
        // TODO:  Inefficient way of doing this. Need to find a better way
        return (
          <SimpleAutoupdateField
            field={field}
            derivedFrom={field.derivedFrom}
            control={control}
            name={field.name}
            setValue={setValue}
            watch={watch}
            register={register}
            error={errors[field.name] ? true : false}
            helperText={errors[field.name]?.message}
          />
        );
      case "switch":
        return (
          <FormGroup>
            <FormControlLabel
              control={
                <Switch
                  size="small"
                  fullWidth
                  label={field.label}
                  checked={field.checked}
                  variant="outlined"
                  {...register(field.name)}
                />
              }
              label={field.label}
              labelPlacement="end"
            />
          </FormGroup>
        );
      case "slider":
        return (
          <Slider
            size="small"
            fullWidth
            label={field.label}
            variant="outlined"
            {...register(field.name)}
          />
        );
      case "autocomplete":
        return (
          <ControlledAutoComplete
            size="small"
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName]
                : []
            }
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            control={control}
            name={field.name}
            multiple={field.multiple}
            collectionLookupKey={field.collectionLookupKey}
          />
        );
      case "multi-autocomplete":
        return (
          <ControlledAutoComplete
            size="small"
            multiple
            collectionLookupKey={field.collectionLookupKey}
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName]
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
          />
        );
      case "notRequired-autocomplete":
        return (
          <ControlledAutoComplete
            size="small"
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName]
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
            multiple={field.multiple}
            collectionLookupKey={field.collectionLookupKey}
          />
        );
      case "autocomplete-array":
        return (
          <ControlledAutoCompleteArray
            size="small"
            multiple
            collectionLookupKey={field.collectionLookupKey}
            fullWidth
            options={
              classTypeCollection[field.collectionName]
                ? classTypeCollection[field.collectionName]
                : []
            }
            label={field.label}
            control={control}
            name={field.name}
          />
        );
      case "ImageUploader":
        return (
          <Box
            padding={2}
            style={{
              background: "#FFFFFF",
              borderRadius: 2,
              height: 200,
              width: 200,
            }}
          >
            {watchAllFields.thumbnail !== "" ? (
              <Box style={{ background: "#FAFAFA" }}>
                <img
                  alt=""
                  src={watchAllFields.thumbnailUrl}
                  style={{
                    display: "block",
                    objectFit: "cover",
                    width: "80%",
                    height: "80%",
                  }}
                />
                {/* <Button
                  variant="text"
                  onClick={() => setFormValue(watchAllFields.thumbnail, "")}
                >
                  Replace Thumbnail
                </Button> */}
              </Box>
            ) : (
              <ImagesDropzone
                setImageList={setImageList}
                storageLocation={
                  schema.collectionName + "/" + watchAllFields.id
                }
              />
            )}
          </Box>
        );
      case "image-uploader":
        const error = errors[field.name];
        return (
          <div key={field.name}>
            <ImageUploader
              multiple={field.multiple}
              name={field.name}
              value={watchAllFields[field.name] || null}
              field={field}
              control={control}
              register={register}
              setFormValue={setValue}
              error={!!error}
              storageLocation={schema.collectionName + "/" + watchAllFields.id}
            />
            {error && (
              <p
                style={{
                  color: "#D32F2F",
                  fontSize: "0.75em",
                  margin: "0px",
                  padding: "0px",
                }}
              ></p>
            )}
          </div>
        );
      case "button":
        return (
          <Button
            checked={field.value}
            label={field.label}
            variant="outlined"
            {...register(field.name)}
          />
        );
      case "checkbox":
        return (
          <ControlledCheckbox
            label={
              <>
                {field.label} <span style={{ color: "red" }}>*</span>
              </>
            }
            variant="outlined"
            name={field.name}
            control={control}
          />
        );
      case "notRequired-checkbox":
        return (
          <ControlledCheckbox
            label={field.label}
            variant="outlined"
            name={field.name}
            control={control}
          />
        );
      case "radio":
        return (
          <Radio
            label={field.label}
            variant="outlined"
            {...register(field.name)}
          />
        );
      case "textarea":
        return (
          <FormControl fullWidth error={descriptionCharCount > 250}>
            <TextField
              fullWidth
              size="small"
              multiline
              rows={4}
              label={field.label}
              variant="outlined"
              {...register(field.name, {
                onChange: (e) => setDescriptionCharCount(e.target.value.length),
              })}
              error={!!errors[field.name] || descriptionCharCount > 250}
              helperText={
                errors[field.name]?.message ||
                (descriptionCharCount > 250
                  ? "Error: Maximum 250 characters allowed"
                  : `${descriptionCharCount}/250 characters`)
              }
              InputProps={{
                inputProps: {
                  maxLength: "250",
                },
              }}
            />
          </FormControl>
        );
      default:
      // causing error as undefined in cuisines thats why commented
      // return (
      //   // <TextField
      //   //   size="small"
      //   //   fullWidth
      //   //   // label={field?.label}
      //   //   variant="outlined"
      //   //   {...register(field.name)}
      //   // />
      // );
    }
  };

  return (
    <Box
      padding={2}
      style={{ background: "white", height: "100vh", overflow: "scroll" }}
    >
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <input
          {...register("recipeList", { validate: recipeListValidation })}
        />
        {errors.recipeList && <span>{errors.recipeList.message}</span>}
        {formMode === "create" && (
          <input
            type="hidden"
            {...register("layoutIndex")}
            value={nextLayoutIndex}
          />
        )}
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{
              top: 0,
              position: "fixed",
              padding: 15,
              background: "#e8e8e8",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Typography variant="h2">
              {formMode === "edit" ? "Update" : "Add"} {schema.name}
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ marginTop: 60, marginBottom: 100 }}>
            <Grid container spacing={2}>
              {formLayout.map((row, rowIndex) => {
                return (
                  <Grid key={rowIndex} item xs={12}>
                    <Grid container spacing={2}>
                      {row.map((column, columnIndex) => {
                        return (
                          <Grid key={columnIndex} item xs={column.width}>
                            {returnField(fieldsObj[column.name])}
                          </Grid>
                        );
                      })}
                    </Grid>
                  </Grid>
                );
              })}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              bottom: 0,
              position: "fixed",
              padding: 15,
              background: "#e8e8e8",
              width: "100%",
              zIndex: 100,
            }}
          >
            <Button type="submit" variant="contained">
              Submit
            </Button>
          </Grid>
        </Grid>
      </form>
      {/* {DEBUG && (
        <Box style={{ width: "80%" }}>
          <ReactJson src={watchAllFields} theme={"monokai"} />
        </Box>
      )} */}
    </Box>
  );
}
