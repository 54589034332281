import { useAuth } from "../../auth/auth-context";
import { v4 as uuidv4 } from "uuid";
import * as Yup from "yup";
import { cookingParameterConstant, difficultyLevel } from "../constants";

const useRecipeConstants = () => {
  const { user } = useAuth();
  const recipeId = uuidv4();
  const sectionId = uuidv4();
  const instructionId = uuidv4();
  const cookingParameterId = uuidv4();
  const authorData = typeof user === "string" ? JSON.parse(user) : user;
  const {
    presetId,
    presetName,
    controlModeId,
    controlModeFirmwareName,
    controlModeFirmwareId,
    semiScreenId,
    isCentralProbeTemperatureRequired,
    isExternalProbeTemperatureRequired,
    isPowerRequired,
    isTimeRequired,
  } = cookingParameterConstant;

  const generationOfNewUuid = () => {
    return uuidv4();
  };
  const validationSchema = Yup.object().shape({
    isDraftRecipe: Yup.boolean(),
    isRecipeProSelected: Yup.boolean(),
    isRikuSelected: Yup.boolean(),
    isSemiSelected: Yup.boolean(),

    metadata: Yup.object().shape({
      title: Yup.string()
        .max(60, "Maximum 60 characters")
        .required("Recipe title cannot be empty"),
      isDraftRecipe: Yup.boolean(),
      recipeDescription: Yup.string().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .max(500, "Cannot exceed 500 characters")
            .required("Recipe description cannot be empty"),
      }),
      recipeServeDescription: Yup.string().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .max(250, "Cannot exceed 250 characters")
            .required("Please describe how to serve the recipe"),
      }),
      thumbnailUrl: Yup.string().when("isDraftRecipe", {
        is: false,
        then: (schema) => schema.required("Image is required"),
      }),

      prepTimeInMins: Yup.number().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .min(0, "Minimum 0 minutes")
            .max(999, "Max 999 minutes")
            .typeError("Must be a number")
            .required("Prep time is required"),
        otherwise: (schema) => schema.nullable(true),
      }),

      cookTimeInMins: Yup.number().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .min(0, "Minimum 0 minutes")
            .max(999, "Max 999 minutes")
            .typeError("Must be a number")
            .required("Cook time is required"),
        otherwise: (schema) => schema.nullable(true),
      }),

      tools: Yup.array().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .min(1, "At least one tool is required")
            .required("Tools field is required"),
      }),
      courses: Yup.array().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .required("Courses field is required")
            .min(1, "At least one course is required")
            .max(3, "Max 3 courses can be selected"),
      }),
      diets: Yup.array().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .min(1, "At least one diet is required")
            .required("Diets field is required"),
      }),
      cuisines: Yup.array().when("isDraftRecipe", {
        is: false,
        then: (schema) =>
          schema
            .min(1, "At least one cuisine is required")
            .required("Cuisines field is required"),
      }),
    }),

    isLoopSection: Yup.boolean(),
    isLoopIngredient: Yup.boolean(),
    recipeIngredients: Yup.array().when(
      ["metadata.isDraftRecipe", "isLoopIngredient"],
      ([isDraftRecipe], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              name: Yup.string().required("Ingredient name is required"),
              quantity: Yup.number()
                .min(0, "Minimum 0 digit")
                .max(999, "Max 999 digit")
                .typeError("Must be a number")
                .required("Required field"),
              units: Yup.string().required("Units are required"),
              unitsPerStep: Yup.string().when("isLoopIngredient", {
                is: (isLoopIngredient) => isLoopIngredient === true,
                then: (schema) => schema.required("units Per Step is required"),
                otherwise: (schema) => schema,
              }),
              quantityPerStep: Yup.number()
                .when("isLoopIngredient", {
                  is: (isLoopIngredient) => isLoopIngredient === true,
                  then: (schema) =>
                    schema
                      .required("Required field")
                      .min(0, "Minimum 0 digit")
                      .max(999, "Max 999 digit")
                      .typeError("Must be a number"),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === null
                    ? undefined
                    : Number(originalValue);
                }),
            })
          );
        }
      }
    ),

    recipeSections: Yup.array().when(
      ["metadata.isDraftRecipe", "isLoopSection"],
      ([isDraftRecipe], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              sectionName: Yup.string()
                .max(60, "Maximum 60 characters")
                .required(" Recipe section is required"),
              loopInstruction: Yup.string().when("isLoopSection", {
                is: (isLoopSection) => isLoopSection === true,
                then: (schema) =>
                  schema
                    .max(60, "Maximum 60 characters")
                    .required("Loop instruction is required"),
                otherwise: (schema) => schema,
              }),
            })
          );
        }
      }
    ),
    recipeInstructions: Yup.array().when(
      "metadata.isDraftRecipe",
      ([isDraftRecipe], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              mentionsTitle: Yup.string()
                // .max(60, "Maximum 60 characters")
                .required("This step instruction is required"),
              tip: Yup.string().max(250, "Maximum 250 characters"),
            })
          );
        }
      }
    ),
    action: Yup.string(),
    cookingParameters: Yup.array().when(
      ["metadata.isDraftRecipe", "action"],
      ([isDraftRecipe, action], schema) => {
        if (!isDraftRecipe) {
          return schema.of(
            Yup.object().shape({
              centralTemperatureInC: Yup.number()
                .when(["action", "isCentralProbeTemperatureRequired"], {
                  is: (action, isCentralProbeTemperatureRequired) =>
                    action !== "" &&
                    isCentralProbeTemperatureRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(40, "Minimum 40 °C.")
                      .max(200, "Maximum 200 °C."),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
              externalTemperatureInC: Yup.number()
                .when(["action", "isExternalProbeTemperatureRequired"], {
                  is: (action, isExternalProbeTemperatureRequired) =>
                    action !== "" &&
                    isExternalProbeTemperatureRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(40, "Minimum 40 °C.")
                      .max(200, "Maximum 200 °C."),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
              powerInLevel: Yup.number()
                .when(["action", "isPowerRequired"], {
                  is: (action, isPowerRequired) =>
                    action !== "" && isPowerRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(1, "Minimum 1 level")
                      .max(10, "Maximum 10 level"),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
              durationInMins: Yup.number()
                .when(["action", "isTimeRequired"], {
                  is: (action, isTimeRequired) =>
                    action !== "" && isTimeRequired === "REQUIRED",
                  then: (schema) =>
                    schema
                      .required("Required")
                      .typeError("Must be a number")
                      .min(0.25, "Minimum 0.25 minute")
                      .max(500, "Maximum 500 minutes"),
                  otherwise: (schema) => schema,
                })
                .transform((value, originalValue) => {
                  return originalValue === ""
                    ? undefined
                    : Number(originalValue);
                }),
            })
          );
        }
        return schema;
      }
    ),
  });

  const generateDefaultValue = {
    metadata: {
      authorId: authorData?.userId || authorData?.id,
      authorName: authorData?.displayName,
      authorThumbnailUrl: authorData?.photoURL,
      title: "",
      recipeDescription: "",
      servings: 2,
      difficultyLevel: difficultyLevel,
      isVerifiedByKlynk: false,
      prepTimeInMins: null,
      cookTimeInMins: null,
      restTimeInMins: null,
      totalTimeInMins: 0,
      productReleases: [],
      courses: [],
      tools: [],
      diets: [],
      cuisines: [],
      tags: [],
      allergies: [],
      prepImage: "",
      notes: "",
      recipeUrl: "",
      recipeServeDescription: "",
      recipeIngredientsLength: 0,
      thumbnailUrl: "",
      sourceUrl: "",
      categories: [],
      recipeIngredientNames: [],
      smartFilters: [],
      scheduledTime: null,
      createdAt: "",
      updatedAt: "",
      isPublished: false,
      isPublic: false,
      isCreatedByKlynk: false,
      id: recipeId,
      recipeId: recipeId,
      isRecipeProSelected: false,
      isRikuSelected: false,
      isSemiSelected: false,
      isDraftRecipe: false,
      isProbeRequired: false,
    },
    recipeIngredients: [
      {
        id: generationOfNewUuid(),
        ingredientId: "",
        isScaleIngredient: false,
        name: "",
        quantity: null,
        quantityPerStep: null,
        ingredient: "",
        ingredientType: "",
        ingredientImage: "",
        prepStyles: "",
        units: "",
        unitsPerStep: "",
        loopingUnit: "",
        loopingQuantity: "",
        schedulingLimit: "",
        isOutput: false,
        isRecipeGenerated: false,
        isUserGenerated: false,
        loadingPosition: "",
        podType: "",
        podPosition: [],
        categoryInContextOfRiku: "",
        recipeId: recipeId,
        sectionId: sectionId,
        instructionId: "",
      },
    ],
    recipeSections: [
      {
        id: sectionId,
        title: "",
        sectionType: "manual",
        sectionIndex: 1,
        isLoopSection: false,
        loopInstruction: "",
        recipeId: recipeId,
        isProbeRequired: false,
      },
    ],
    recipeInstructions: [
      {
        "@type": "HowToStep",
        id: instructionId,
        action: "", // removed
        actions: [], // removed
        mentionsTitle: "",
        recipeId: recipeId,
        sectionId: sectionId,
        tip: "",
        title: "",
        tools: [],
        instructionIndex: 1,
        stepImageUrl: "",
        stepVideoUrl: "",
      },
    ],

    cookingParameters: [
      {
        id: cookingParameterId,
        sectionId: sectionId,
        instructionId: instructionId,
        recipeId: recipeId,
        stirring: "",
        isLidOpen: false,
        action: "",
        actionId: "",
        powerInLevel: null,
        centralTemperatureInC: null,
        externalTemperatureInC: null,
        durationInMins: null,
        tempSensor: "",
        instructionIndex: 1,
        manualHeatLevel: "",
        presetId,
        presetName,
        controlModeId,
        controlModeFirmwareName,
        controlModeFirmwareId,
        semiScreenId,
        isCentralProbeTemperatureRequired,
        isExternalProbeTemperatureRequired,
        isPowerRequired,
        isTimeRequired,
      },
    ],
  };
  const userLogs = {
    userId: authorData?.userId || authorData?.id,
    userName: authorData?.displayName,
    createdAt: "",
    operationType: "",
    module: "recipes",
    diiferenceOfRecord: "",
  };

  return { generateDefaultValue, validationSchema, userLogs };
};

export default useRecipeConstants;
