import React from "react";
import { Autocomplete, TextField, createFilterOptions } from "@mui/material";
import { useController, useFormContext } from "react-hook-form";

const capitalizeFirstLetter = (input) => {
  if (typeof input === "string") {
    return input.charAt(0).toUpperCase() + input.slice(1);
  } else if (typeof input === "object" && input !== null && "name" in input) {
    return {
      ...input,
      name: input.name.charAt(0).toUpperCase() + input.name.slice(1),
    };
  }
  return input;
};

const ModifiedAutoCompleteWithOptionsObject = (props) => {
  const {
    control,
    name,
    options,
    multiple,
    parentIndex,
    ingredientsFromDB,
    isError,
    recipeIngredients,
    isLoopSection,
    selectIngredientHandler,
  } = props;
  const { setValue, clearErrors } = useFormContext();
  const { field } = useController({
    name,
    control,
    rules: { required: true },
  });

  const capitalizedOptions = options.map(capitalizeFirstLetter);

  const filterOptions = createFilterOptions({
    matchFrom: "start",
    stringify: (option) => option,
  });

  const handleAutocompleteChange = (evt, commonValue) => {
    const capitalizedValue = capitalizeFirstLetter(commonValue);

    if (name.split(".")[2] === "name") {
      selectIngredientHandler(capitalizedValue);

      const filteredIngredientsFromDB =
        ingredientsFromDB?.filter(Boolean) || [];

      const matchingIngredient = filteredIngredientsFromDB.find((val) => {
        return (
          val &&
          val.name &&
          val.name.toLowerCase() ===
            (typeof capitalizedValue === "string"
              ? capitalizedValue.toLowerCase()
              : (capitalizedValue?.name || "").toLowerCase())
        );
      });

      if (matchingIngredient) {
        setValue(
          `recipeIngredients.${parentIndex}.ingredientId`,
          matchingIngredient?.id
        );

        setValue(
          `recipeIngredients.${parentIndex}.ingredientImage`,
          matchingIngredient?.thumbnailUrl || matchingIngredient?.imageUrl
        );

        setValue(
          `recipeIngredients.${parentIndex}.ingredientType`,
          matchingIngredient?.category?.name
        );

        if (matchingIngredient?.defaultUnit) {
          setValue(
            `recipeIngredients.${parentIndex}.units`,
            capitalizeFirstLetter(matchingIngredient?.defaultUnit || "")
          );
          clearErrors(`recipeIngredients.${parentIndex}.units`);
        }

        setValue(
          `recipeIngredients.${parentIndex}.prepStyles`,
          matchingIngredient?.defaultPrepStyle || ""
        );

        if (isLoopSection) {
          setValue(
            `recipeIngredients.${parentIndex}.unitsPerStep`,
            recipeIngredients[parentIndex].units
          );
          clearErrors(`recipeIngredients.${parentIndex}.unitsPerStep`);
        }
      } else {
        setValue(`recipeIngredients.${parentIndex}.ingredientId`, "");
        setValue(`recipeIngredients.${parentIndex}.ingredientImage`, "");
        setValue(`recipeIngredients.${parentIndex}.ingredientType`, "");
        setValue(`recipeIngredients.${parentIndex}.units`, "");
        setValue(`recipeIngredients.${parentIndex}.prepStyles`, "");
        setValue(`recipeIngredients.${parentIndex}.unitsPerStep`, "");
      }
    }

    field.onChange(capitalizedValue);
  };

  return (
    <Autocomplete
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          const inputValue = event.target.value;
          const isOption = capitalizedOptions.some((option) =>
            typeof option === "string"
              ? option === inputValue
              : option.name === inputValue
          );
          if (!isOption) {
            event.defaultMuiPrevented = true;
          }
        }
      }}
      size={props.size ? props.size : "small"}
      multiple={multiple ? multiple : false}
      sx={{
        border: isError && "1px solid red",
        borderRadius: isError && "4px",
        padding: isError && "4px",
        "&:hover": {
          "&::after": {
            content: props?.disabled ? '"all ready used in some step"' : '""',
            color: props?.disabled && "red",
          },
        },
      }}
      disablePortal
      disableUnderline
      disabledItemsFocusable={props.disabledItemsFocusable}
      disabled={props.disabled}
      label={field.name}
      id={field.name}
      onBlur={field.onBlur}
      onChange={handleAutocompleteChange}
      // onChange={field.onChange}
      autoHighlight={true}
      name={field.name}
      ref={field.ref}
      freeSolo
      value={field.value}
      options={capitalizedOptions}
      filterOptions={filterOptions}
      ListboxProps={{
        style: {
          maxHeight: "20vh",
          color: "#1C1C1C",
          fontFamily: "Mukta Mahee",
          fontSize: "14px",
          fontWeight: 500,
          background: "#F7F7F7",
          position: "bottom-start",
        },
      }}
      renderInput={(params) => (
        <TextField
          disableUnderline
          {...params}
          fullWidth
          variant={props.variant ? props.variant : "standard"}
          sx={{
            ".MuiInputBase-input": {
              fontFamily: "Mukta Mahee",
              fontSize: "16px",
              fontWeight: 400,
              color: "#393939",
            },
          }}
          label={props.label}
          placeholder={props.placeholder ? props.placeholder : "select"}
          InputProps={{
            ...params.InputProps,
            ...props.InputProps,
            disableUnderline: true,
          }}
        />
      )}
    />
  );
};

export default ModifiedAutoCompleteWithOptionsObject;
