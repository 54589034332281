import React, { useState, useEffect, useRef } from "react";
import { DataGrid, GridRowId } from "@mui/x-data-grid";
import {
  Typography,
  Modal,
  IconButton,
  Box,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";

import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import FormGenerator from "./form-generator";
import useEntity from "./use-entity";
import { useAuth } from "../auth/auth-context";
import { FirebaseApi, firestore } from "../../config/firebase-config";
import { userLogsCollection } from "../../utils/constants";

export default function EntityTable(props) {
  const { user } = useAuth();
  const [entityName, setEntityName] = useState(props.entityName);
  const { loading, loadingMessage, getEntities, deleteEntity } = useEntity();
  const [rowData, setRowData] = useState([]);
  const gridApiRef = useRef();

  const [entityModel, setEntityModel] = useState(props.entityModel);
  const [selectedRows, setSelectedRows] = useState([]);
  const [canEdit, setCanEdit] = useState(false);
  const [canDelete, setCanDelete] = useState(false);
  const [formMode, setFormMode] = useState("create");
  const [formData, setFormData] = useState({});
  const [isCreateEditModalOpen, setIsCreateEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  const isRecipeFilterGroups = entityName === "recipeFilterGroups";

  useEffect(() => {
    getEntities(entityName, (entities) => {
      const entitiesWithIndex = entities.map((entity, index) => ({
        ...entity,
        layoutIndex: entity.layoutIndex || index + 1,
      }));
      setRowData(sortRowsByIndex(entitiesWithIndex));
      setCurrentIndex(entities.length);
    });
    return () => {
      setRowData([]);
    };
  }, [entityName]);

  useEffect(() => {
    if (selectedRows.length === 1) {
      setCanEdit(true);
      setCanDelete(true);
    } else {
      setCanEdit(false);
      setCanDelete(false);
    }
  }, [selectedRows]);

  const handleSelectionChange = (newSelection) => {
    setSelectedRows(newSelection);
  };

  const handleDeleteOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteClose = () => {
    setIsDeleteModalOpen(false);
  };

  const addUserLogsHandler = async (data) => {
    try {
      const userLogs = {
        userId: user?.userId || "",
        userName: user?.displayName || "",
        createdAt: "",
        operationType: "delete",
        module: entityName,
        diiferenceOfRecord: "",
        newRecord: JSON.stringify(data),
      };

      await FirebaseApi[userLogsCollection].add(userLogs);
    } catch (error) {
      console.error("Error writing user log: ", error);
    }
  };

  const handleConfirmDelete = async () => {
    handleDeleteClose();

    if (selectedRows.length > 0) {
      const deletedRow = rowData.find((row) => row.id === selectedRows[0]);
      await deleteEntity(entityName, selectedRows[0]);

      const updatedRows = rowData.filter((row) => row.id !== selectedRows[0]);

      // Reindexing the remaining rows
      const reindexedRows = reindexRows(updatedRows, deletedRow.layoutIndex);

      setRowData(reindexedRows);
      setSelectedRows([]);

      // Updating the indexes in the database
      await updateLayoutIndexesInDatabase(reindexedRows);
      await addUserLogsHandler(deletedRow);
    }
  };

  const reindexRows = (rows, startIndex) => {
    return rows
      .map((row, index) => {
        if (row.layoutIndex > startIndex) {
          return { ...row, layoutIndex: row.layoutIndex - 1 };
        }
        return row;
      })
      .sort((a, b) => a.layoutIndex - b.layoutIndex);
  };

  const updateLayoutIndexesInDatabase = async (rows) => {
    const batch = firestore.batch();

    rows.forEach((row) => {
      const rowRef = firestore.collection(entityName).doc(row.id);
      batch.update(rowRef, { layoutIndex: row.layoutIndex });
    });

    try {
      await batch.commit();
      console.log("Layout indexes updated successfully");
    } catch (error) {
      console.error("Error updating layout indexes:", error);
    }
  };

  const generateColumns = (entityModel) => {
    const columns = [];
    entityModel.fields.forEach((field) => {
      if (field.showInListLayout) {
        columns.push({
          field: field.name,
          headerName: field.label,
          width: field.listLayoutWidth,
          renderCell: field.listLayoutComponent,
        });
      }
    });
    return columns;
  };

  const columns = generateColumns(entityModel);

  const toggleCreateEditModal = (mode) => {
    if (mode === "create") {
      setFormMode("create");
    } else {
      setFormMode("edit");
      setFormData(rowData.find((row) => row.id === selectedRows[0]) || {});
    }
    setIsCreateEditModalOpen(!isCreateEditModalOpen);
  };

  const updateValue = (value) => {
    if (formMode === "create") {
      const newRow = isRecipeFilterGroups
        ? {
            ...value,
            layoutIndex:
              Math.max(...rowData.map((row) => row.layoutIndex), 0) + 1,
          }
        : value;
      setRowData(
        isRecipeFilterGroups
          ? sortRowsByIndex([...rowData, newRow])
          : [...rowData, newRow]
      );
    }
    if (formMode === "edit") {
      setRowData(
        isRecipeFilterGroups
          ? sortRowsByIndex(
              rowData.map((row) =>
                row.id === value.id
                  ? { ...value, layoutIndex: row.layoutIndex }
                  : row
              )
            )
          : rowData.map((row) => (row.id === value.id ? value : row))
      );
    }
    setFormData(value);
  };

  const moveRowUp = () => {
    if (!isRecipeFilterGroups || selectedRows.length !== 1) return;

    const selectedIndex = rowData.findIndex(
      (row) => row.id === selectedRows[0]
    );
    if (selectedIndex > 0) {
      const updatedRows = [...rowData];
      // Swap with the previous row
      const temp = updatedRows[selectedIndex];
      updatedRows[selectedIndex] = updatedRows[selectedIndex - 1];
      updatedRows[selectedIndex - 1] = temp;

      // Update layoutIndex
      const tempIndex = updatedRows[selectedIndex].layoutIndex;
      updatedRows[selectedIndex].layoutIndex =
        updatedRows[selectedIndex - 1].layoutIndex;
      updatedRows[selectedIndex - 1].layoutIndex = tempIndex;

      // Re-sort by index
      setRowData(sortRowsByIndex(updatedRows));

      // Save changes to the database
      saveUpdatedIndices(
        updatedRows[selectedIndex],
        updatedRows[selectedIndex - 1]
      );

      // Show success message
      setSnackbarMessage("Recipe Filter Group swapped successfully");
      setSnackbarOpen(true);
    }
  };

  const moveRowDown = () => {
    if (!isRecipeFilterGroups || selectedRows.length !== 1) return;

    const selectedIndex = rowData.findIndex(
      (row) => row.id === selectedRows[0]
    );
    if (selectedIndex < rowData.length - 1) {
      const updatedRows = [...rowData];
      // Swap with the next row
      const temp = updatedRows[selectedIndex];
      updatedRows[selectedIndex] = updatedRows[selectedIndex + 1];
      updatedRows[selectedIndex + 1] = temp;

      // Update layoutIndex
      const tempIndex = updatedRows[selectedIndex].layoutIndex;
      updatedRows[selectedIndex].layoutIndex =
        updatedRows[selectedIndex + 1].layoutIndex;
      updatedRows[selectedIndex + 1].layoutIndex = tempIndex;

      // Re-sort by index
      setRowData(sortRowsByIndex(updatedRows));

      // Save changes to the database
      saveUpdatedIndices(
        updatedRows[selectedIndex],
        updatedRows[selectedIndex + 1]
      );

      // Show success message
      setSnackbarMessage("Recipe Filter Group swapped successfully");
      setSnackbarOpen(true);
    }
  };

  const sortRowsByIndex = (rows) => {
    return [...rows].sort((a, b) => a.layoutIndex - b.layoutIndex);
  };

  const saveUpdatedIndices = async (row1, row2) => {
    try {
      const batch = firestore.batch();
      const row1Ref = firestore.collection(entityName).doc(row1.id);
      const row2Ref = firestore.collection(entityName).doc(row2.id);

      batch.update(row1Ref, { layoutIndex: row1.layoutIndex });
      batch.update(row2Ref, { layoutIndex: row2.layoutIndex });

      await batch.commit();
      console.log("Updated indices saved successfully");
    } catch (error) {
      console.error("Error saving updated indices:", error);
    }
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box style={{ height: "100%" }} padding={2}>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={loading}
        message={loadingMessage}
        autoHideDuration={6000}
      />
      <Modal open={isCreateEditModalOpen} onClose={toggleCreateEditModal}>
        <Box style={style}>
          {props.customCRUD ? (
            <props.customFormComponent
              schema={entityModel}
              mode={formMode}
              viewStyle={"modal"}
              values={formData}
              updateValue={updateValue}
              toggleModal={() => setIsCreateEditModalOpen(false)}
            />
          ) : (
            <FormGenerator
              schema={entityModel}
              mode={formMode}
              viewStyle={"modal"}
              values={formData}
              updateValue={updateValue}
              toggleModal={() => setIsCreateEditModalOpen(false)}
            />
          )}
        </Box>
      </Modal>
      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteModalOpen} onClose={handleDeleteClose}>
        <DialogTitle>Confirm Delete</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDeleteClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>

      <Grid container spacing={2}>
        <Grid
          item
          xs={12}
          style={{
            height: "10vh",
            borderRadius: 5,
            marginTop: 5,
            marginLeft: 10,
          }}
          padding={2}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h5">{entityModel.name}</Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              {(user?.access === "Admin" || user?.access === "Editor") && (
                <>
                  {" "}
                  <IconButton
                    aria-label="Add"
                    onClick={() => toggleCreateEditModal("create")}
                  >
                    <AddCircleOutlineIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Edit"
                    disabled={!canEdit}
                    onClick={() => toggleCreateEditModal("edit")}
                  >
                    <ModeEditIcon />
                  </IconButton>
                </>
              )}
              {user?.access === "Admin" && (
                <IconButton
                  aria-label="Delete"
                  disabled={!canDelete}
                  onClick={handleDeleteOpen} // Open delete modal instead
                >
                  <DeleteIcon />
                </IconButton>
              )}
              {isRecipeFilterGroups && (
                <>
                  <IconButton
                    color="primary"
                    disabled={!canEdit}
                    onClick={moveRowUp}
                  >
                    <ArrowUpwardIcon />
                  </IconButton>
                  <IconButton
                    color="primary"
                    disabled={!canEdit}
                    onClick={moveRowDown}
                  >
                    <ArrowDownwardIcon />
                  </IconButton>
                </>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: "85vh", width: "100%" }}>
            <DataGrid
              rows={rowData}
              columns={columns}
              pageSize={50}
              rowsPerPageOptions={[5]}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={handleSelectionChange}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

const style = {
  position: "absolute",
  top: 0,
  right: 0,
  width: "50%",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};
