/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Typography, Modal, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";
import ReorderImage from "../assets/reorder-img.png";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 532,
  background: "#fff",
  borderRadius: "8px",
  boxShadow: "0px 0px 20px 0px rgba(0, 0, 0, 0.15)",
  padding: "32px 24px 10px",
  overflowY: "auto",
};

export default function ReorderStepsModal(props) {
  const { openReorder, setOpenReorder, sectionId, setSelectedInstruction } =
    props;
  const { setValue, getValues } = useFormContext();
  const recipeSections = getValues("recipeSections");
  const recipeInstructions = getValues("recipeInstructions");
  const filteredRecipeSections = (section) =>
    section.filter((item) => item.id === sectionId);

  const filteredRecipeInstructions = useCallback(
    () => recipeInstructions.filter((item) => item.sectionId === sectionId),
    [recipeInstructions, sectionId]
  );

  const dragItem = useRef(null);
  const draggedOverItem = useRef(null);
  const [reorderItems, setReorderItems] = useState(filteredRecipeInstructions);

  const handleSort = () => {
    const itemClone = [...reorderItems];
    const draggedItemContent = itemClone.splice(dragItem.current, 1)[0];
    itemClone.splice(draggedOverItem.current, 0, draggedItemContent);

    const reorderedSteps = itemClone.map((item, index) => ({
      ...item,
      instructionIndex: index + 1,
    }));

    dragItem.current = null;
    draggedOverItem.current = null;
    setReorderItems(reorderedSteps);
  };

  const filteredOutRecipeInstructions = (instructions) =>
    instructions.filter((item) => item.sectionId !== sectionId);

  const indexofSection = recipeSections.findIndex(
    (item) => item.id === sectionId
  );

  useEffect(() => {
    setReorderItems(filteredRecipeInstructions());
  }, [recipeInstructions, filteredRecipeInstructions]);

  const onSaveHandler = async () => {
    await setValue("recipeInstructions", [
      ...filteredOutRecipeInstructions(recipeInstructions),
      ...reorderItems,
    ]);
    setSelectedInstruction(reorderItems[0]?.id);
    setOpenReorder(false);
  };
  const onCloseHandler = () => {
    setReorderItems(filteredRecipeInstructions());
    setOpenReorder(false);
  };

  return (
    <Modal
      open={openReorder}
      onClose={onCloseHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Grid container direction="column">
          <Grid item>
            <Grid
              xs={12}
              container
              justifyContent="space-between"
              background="#fff"
            >
              <Grid item>
                {" "}
                <Typography variant="h4" sx={{ fontSize: "18px" }}>
                  Reordering Steps
                </Typography>
                <Typography
                  marginY={1}
                  variant="body2"
                  sx={{ fontSize: "14px", color: "#969696" }}
                >
                  {`Section ${
                    indexofSection <= 9
                      ? `0${indexofSection + 1}`
                      : indexofSection + 1
                  } | ${
                    filteredRecipeSections(recipeSections)[0]?.sectionName ||
                    "section"
                  }`}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={onSaveHandler}
                  sx={{
                    background:
                      " linear-gradient(90deg, #EF4823 0%, #F47422 100%)",
                    padding: " 8px 16px",
                  }}
                >
                  <Typography
                    variant="body3"
                    sx={{ textTransform: "none", color: "#fff" }}
                  >
                    Save Changes
                  </Typography>
                </Button>{" "}
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Box
              sx={{
                height: "100%",
                paddingBottom: "10px",
                "&::-webkit-scrollbar": {
                  display: "none" /* WebKit */,
                },
              }}
            >
              {reorderItems.map((item, index) => (
                <div
                  key={item.id}
                  draggable
                  onDragStart={() => (dragItem.current = index)}
                  onDragEnter={() => (draggedOverItem.current = index)}
                  onDragEnd={handleSort}
                  onDragOver={(e) => e.preventDefault()}
                  style={{
                    height: 42,
                    background: "#F1F1F1",
                    padding: "0px 10px",
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    borderRadius: "4px",
                  }}
                >
                  <Typography variant="body4" sx={{ overflow: "hidden" }}>
                    {`${index + 1}.  ${
                      item?.title
                        ? item.title.length < 40
                          ? item?.title
                          : item.title.slice(0, 40)
                        : `Step ${index + 1} `
                    } `}
                  </Typography>
                  <img
                    src={ReorderImage}
                    alt=""
                    style={{ width: 20, height: 20 }}
                  />
                </div>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  );
}
