/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-unused-vars */
import React from "react";
import {
  mixed,
  string,
  number,
  boolean,
  bool,
  date,
  object,
  array,
  ref,
  lazy,
} from "yup";
import { nameValidation } from "../units/units-model";

const CookingActions = {
  name: "Actions",
  model: "actions",
  collectionName: "actions",
  defaultObject: {
    id: "",
    name: null,
    description: "",
    type: "",
    showInRecipeBuilder: false,
    // thumbnail: "",
    createdAt: "",
    updatedAt: "",
  },
  fields: [
    // {
    //   name: "thumbnail",
    //   dataType: "String",
    //   inputFieldType: "ImageUploader",
    //   label: "Thumbnail",
    //   required: false,
    //   showInEditLayout: true,
    //   editLayoutWidth: 12,
    //   showInListLayout: false,
    //   listLayoutWidth: 200,
    // },

    {
      name: "name",
      dataType: "String",
      inputFieldType: "text-modified",
      label: "Name",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 150,
      required: true,
      validation: nameValidation,
      listLayoutComponent: (props) => {
        const name = props.row?.name;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{name}</span>
          </div>
        );
      },
    },
    {
      name: "type",
      dataType: "String",
      inputFieldType: "select",
      options: ["Heat", "Non-heat"],
      label: "Type",
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 125,
      required: true,
      validation: string().required("Type is required"),
      placeholder: "Select the type"
    },
    {
      name: "showInRecipeBuilder",
      dataType: "Boolean",
      inputFieldType: "checkbox",
      label: "Show in Recipe Builder",
      required: true,
      showInEditLayout: true,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 100,
      validation: bool().required("Required"),
    },
    {
      name: "description",
      dataType: "String",
      inputFieldType: "textarea",
      label: "Description",
      showInEditLayout: true,
      editLayoutWidth: 12,
      showInListLayout: true,
      listLayoutWidth: 300,
      listLayoutComponent: (props) => {
        const description = props.row?.description;
        return (
          <div
            style={{
              maxWidth: props.listLayoutWidth,
              overflowX: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "none",
              msOverflowStyle: "none",
            }}
          >
            <span>{description}</span>
          </div>
        );
      },
    },
    {
      name: "createdAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Created At",
      required: false,
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 125,
    },
    {
      name: "updatedAt",
      dataType: "Date",
      inputFieldType: "string",
      label: "Updated At",
      required: false,
      showInEditLayout: false,
      editLayoutWidth: 6,
      showInListLayout: true,
      listLayoutWidth: 125,
    },
  ],
  layout: [
    // [{ name: "thumbnail", width: 6 }],
    [{ name: "name", width: 6 }],
    [{ name: "showInRecipeBuilder", width: 6 }],
    [{ name: "type", width: 3 }],
    [{ name: "description", width: 12 }],
  ],
};

export default CookingActions;
